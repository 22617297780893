
/*
** back
*/

.back {

}

.back__anchor {
	color: inherit;
	transition: transform 300ms;
}

.back__anchor:hover {
	transform: translateX(-10%);
}

/* button */
.back .button__svg {
	transform: translateY(-10%);
}

.back .button__label {
	padding-left: 0.2em;
	padding-bottom: 0.1em;

	font-size: 0.9em;
	text-transform: lowercase;
}