
/*
** actuality
*/

body.body--actuality .header {
	position: fixed;
}


.section--actuality {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}