
/*
** paragraphs
*/

.paragraphs {

}

.paragraphs__container {

}

.paragraphs__item {

}

.paragraphs__wrapper {

}

/* visual */
.paragraphs__visual {

}

.paragraphs__image {

}

/* content */
.paragraphs__content {

}

.paragraphs__info {

}

.paragraphs__name {
	font-size: 1.5em;
	font-weight: bold;
}

.paragraphs__text {

}



/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.paragraphs__wrapper.wrapper {
		display: flex;
		flex-direction: row;
	}

	.paragraphs__item:nth-child(even) .paragraphs__wrapper {
		flex-direction: row-reverse;
	}

	.paragraphs__visual,
	.paragraphs__content {
		flex: 1;
	}

	.paragraphs__visual {
		display: flex;
		justify-content: flex-end;
	}

	.paragraphs__item:nth-child(even) .paragraphs__visual {
		justify-content: flex-start;
	}

	.paragraphs__image {
		padding-top: 5em;
		width: 100%;
		max-width: 22em;
	}

	.paragraphs__content {
		padding-left: 2em;
		padding-right: 2em;
	}
}

@media only screen and ( min-width: 901px ) {

	.paragraphs__wrapper.wrapper {
		padding: 4em;
	}

	.paragraphs__content {
		padding-left: 5em;
		padding-right: 5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.paragraphs__visual {
		margin-bottom: 2em;
	}

	.paragraphs__item {
		margin-bottom: 2em;
	}
}