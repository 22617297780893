
/*
** scroll
*/

.scroll {
	position: fixed;
	top: 50%;
	right: 1em;
	z-index: 1;

	transform: translateY(-50%);
}

.scroll.scroll--hidden {
	display: none;
}

body.body--scrolled .scroll {
	display: none;
}

.scroll__svg {
	width: 1.5em;
	height: 2em;

	animation: animationScroll 1s ease-in-out infinite;
}

@keyframes animationScroll
{
	0%   { transform: translateY( 0%); }
	50%  { transform: translateY(-50%); }
	100% { transform: translateY( 0%); }
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}