
/*
** box
*/

a.box {
	display: inline-block;
	padding: 0.5em 1.5em;
	border: 1px solid currentColor;
	border-radius: 2em;

	color: currentColor;
	text-align: center;
	text-decoration: none;
}

a.box:hover {

}