
/*
** close
*/

.close {
	display: block;
	position: relative;
	cursor: pointer;

	width: 4em;
	height: 4em;

	line-height: 9999px;
	overflow: hidden;
	transition: opacity 600ms;
}

.close:hover {

}

.close::before,
.close::after {
	content: '';

	position: absolute;
	top: 50%;
	left: 50%;
	height: 1px;
	width: 1em;

	margin-left: -0.5em;
	background: currentColor;
}

.close::before {
	transform: rotate(45deg);
}

.close::after {
	transform: rotate(-45deg);
}