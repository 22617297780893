
/* normalize */
@import url("./normalize.css");

/* main */
@import url("./font.css");
@import url("./main.css");

/* class */
@import url("./class/anchor.css");
@import url("./class/box.css");
@import url("./class/browserupgrade.css");
@import url("./class/burger.css");
@import url("./class/button.css");
@import url("./class/close.css");
@import url("./class/embed.css");
@import url("./class/lazyload.css");
@import url("./class/loader.css");
@import url("./class/nprogress.css");
@import url("./class/preloader.css");
@import url("./class/underline.css");
@import url("./class/vignette.css");

/* block : instagram */
@import url("./block/instagram/feed.css");
@import url("./block/instagram/instagram.css");

/* block : news */
@import url("./block/news/news.css");

/* block : page */
@import url("./block/page/page/page-no-poster.css");
@import url("./block/page/page/page-poster.css");
@import url("./block/page/page/page.css");
@import url("./block/page/block.css");
@import url("./block/page/images.css");
@import url("./block/page/panel.css");
@import url("./block/page/paragraphs.css");
@import url("./block/page/pdfs.css");
@import url("./block/page/poster.css");
@import url("./block/page/quote.css");
@import url("./block/page/reader.css");
@import url("./block/page/tags.css");
@import url("./block/page/videos.css");

/* block : player */
@import url("./block/player/player.css");
@import url("./block/player/swiper.css");

/* block : shortcode */
@import url("./block/shortcode/image.css");

/* block : works */
@import url("./block/works/rollover.css");
@import url("./block/works/works-fullrow.css");
@import url("./block/works/works-grid.css");
@import url("./block/works/works.css");

/* block : wysiwyg */
@import url("./block/wysiwyg/wysiwyg-category.css");
@import url("./block/wysiwyg/wysiwyg-images.css");
@import url("./block/wysiwyg/wysiwyg-news.css");
@import url("./block/wysiwyg/wysiwyg-page.css");
@import url("./block/wysiwyg/wysiwyg-quote.css");
@import url("./block/wysiwyg/wysiwyg-videos.css");
@import url("./block/wysiwyg/wysiwyg-work.css");
@import url("./block/wysiwyg/wysiwyg.css");

/* navigation */
@import url("./navigation/back.css");
@import url("./navigation/categories.css");
@import url("./navigation/langs.css");
@import url("./navigation/logo.css");
@import url("./navigation/menu.css");
@import url("./navigation/newsletter.css");
@import url("./navigation/pagination.css");
@import url("./navigation/scroll.css");
@import url("./navigation/sections.css");
@import url("./navigation/socials.css");
@import url("./navigation/top.css");

/* layout */
@import url("./layout/footer.css");
@import url("./layout/header.css");
@import url("./layout/main.css");
@import url("./layout/section.css");
@import url("./layout/wrapper.css");

/* section */
@import url("./section/actualities.css");
@import url("./section/actuality.css");
@import url("./section/contact.css");
@import url("./section/home.css");
@import url("./section/newsletter.css");
@import url("./section/project.css");
@import url("./section/projects.css");
@import url("./section/studio.css");

/* dependencies */
@import url("aos/dist/aos.css");
@import url("../../node_modules/swiper/swiper-bundle.css");

