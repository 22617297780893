
/*
** images
*/

.images {
	width: 100%;
	overflow: hidden;
}

.images__container {

}

.images__item {

}

.images__wrapper {

}

.images__thumbnail {

}

.images__vignette {

}

.images__info {

}

.images__text {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.images__container {
		position: relative;
		left: -5vw;
		width: calc(100% + 10vw);

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.images__sizer {
		display: none;
	}

	.images__sizer,
	.images__item {
		width: 50%;
	}

	.images__item {
		padding-left: 5vw;
		padding-right: 5vw;
		margin-bottom: 15vw;
	}

	.images__item:nth-child(3) {
		padding-top: 10vw;
	}

	.images__wrapper {
		display: flex;
		justify-content: center;
	}

	.images__item .images__content { width: 100%; }
	.images__item.portrait .images__content { width: 65%; }

	.images__info {
		margin-top: 0.7em;

		opacity: 0;
		transition: opacity 600ms;
	}

	.images__item.landscape .images__info {
		padding-left: 1em;
		padding-right: 1em;
	}

	.images__item:hover .images__info {
		opacity: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.images__item {
		margin-bottom: 3em;
		pointer-events: none;
	}

	.images__info {
		margin-top: 0.5em;
	}
}