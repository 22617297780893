
/*
** wysiwyg - quote
*/

.wysiwyg.wysiwyg--quote div,
.wysiwyg.wysiwyg--quote h3 {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	/* word */
	.wysiwyg.wysiwyg--quote p .word {
		display: inline-block;
	}

	.wysiwyg.wysiwyg--quote p .word__wrapper {
		display: block;
		opacity: 0;
		transform: translateY(100%);
		transition: opacity 600ms ease, transform 600ms ease;
	}

	.wysiwyg.wysiwyg--quote p.enter .word__wrapper {
		opacity: 1;
		transform: translateY(0%);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}