
/*
** player (z-index: 900)
*/

.player {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 900;

	color: #000;
	background-color: #fff;
	overflow: hidden;

	transform: translateX(100%);
	transition: all 600ms ease-in-out;
}

.player__swiper {

}

.player__close {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
}

/* opened */
body.body--player-opened .player {
	transform: translateX(0%);
}
