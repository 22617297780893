
/*
** poster
*/

.poster {
	position: relative;
}

.poster__wrapper {
	position: relative;
	background-color: #111;
}

.poster__wrapper:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	content: '';
	background-color: #111;
	mix-blend-mode: difference;

}

/* image */
.poster__image {
	width: 100%;
	height: 100vh;
}

.poster__image .img,
.poster__image .video {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: cover;
}

/* copyright */
.poster__copyright {
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: 100;

	padding: 1em;
}

.poster__copyright span {
	font-size: 0.8em;
}

/* link */
.poster__link {

}

.poster__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 101;

	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}