
/*
** page - no poster
*/

.page.page--no-poster {
	min-height: calc(100vh - 25em);
}

.page.page--no-poster .page__reader {
	margin-top: 2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.page--no-poster .page__info {
		max-width: 40em;
		margin-top: 6em;
		margin-left: 20em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page.page--no-poster .page__info {
		margin-top: 5em;
	}
}