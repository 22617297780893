
/*
** contact
** page / poster / wysiwyg-page
*/

body.body--contact:not(.body--scrolled) {
	color: var(--html-main-color);
	background-color: var(--html-main-bgcolor);
}

.section--contact {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}