
/* root */
:root {
	--html-main-color: #ffffff;
	--html-main-bgcolor: #222222;
	--html-main-opacity: 0.3;
	--html-main-quote-bg-opacity: 1;
}

/* html */
html {
	font-size: 100%;
	height: 100%;
}

/* body */
body {
	font-family: 'HN', Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 1em;
	line-height: 1.5em;
	font-weight: normal;
	letter-spacing: 0em;

	color: #000;
	background-color: #fff;

	width: 100%;
	overflow: hidden;
	overflow-y: scroll;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transition: all 300ms;
}

/* box sizing */
*, *:before, *:after {
	box-sizing: border-box;
}

/* selection */
::selection { }

/* anchor */
a {
	color: inherit;
	text-decoration: none;
}

a:focus {
	outline: none;
}

/* figure */
figure {
	margin: 0;
	line-height: 0;
	color: transparent;
}

/* h1, h2, h3, h4, h5, h6 */
h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;

	font-size: 1em;
	line-height: 1.2em;
	font-weight: normal;
}

/*
** 320 x 480
** 360 x 640
** 768 x 1024
** 800 x 1280
** 1280 x 600
** 1920 x 900
*/

/* ipad */
@media only screen and ( min-width: 811px ) {

}

/* large screen */
@media only screen and ( min-width: 1441px ) {

	body { font-size: 1.2em }
}

@media only screen and ( min-width: 1921px ) {

	body { font-size: 1.5em }
}
