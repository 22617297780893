
/*
** wrapper
*/

.wrapper {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.wrapper {
		padding: 2em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.wrapper {
		padding: 1em;
	}
}