
/*
** wysiwyg - news
*/

.wysiwyg.wysiwyg--news h3 {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}