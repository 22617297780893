
/*
** works
** works-fullrow / works-grid
*/

.works {
	width: 100%;
	overflow: hidden;
}

.works__container {

}

.works__item {

}

.works__wrapper {
	position: relative;
}

.works__info {
	margin-top: 0.5em;
}

.works__name {
	font-size: 0.9em;
}

.works__subtitle {
	display: none;
}

.works__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {


}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}