
/*
** main
*/

.main {
	position: relative;
	top: 0;
	left: 0;
	transition: left 600ms ease-in-out;
}

body.body--instagram .main {
	left: -60vw;
}

body.body--instagram .header {
	display: none;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}