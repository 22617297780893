
/*
** categories
*/

.categories {
	margin: 0;
	padding: 0;
	list-style: none;
}

.categories__item {

}

.categories__item:not(:last-child) .categories__anchor:after {
	content: ', ';
}

.categories__anchor {
	font-family: 'HLTSUC';
	font-size: 1em;
	line-height: 1em;
}

.categories__anchor:hover,
.categories__anchor.selected {
	text-decoration: underline;
}


/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.categories__anchor {
		font-size: 2.5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.categories__anchor {
		font-size: 3em;
	}
}