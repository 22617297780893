
/*
** feed
*/

.feed {

}

.feed__container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.feed__item {
	position: relative;
	width: 25%;
}

.feed__wrapper {
	position: relative;
}

.feed__anchor {
	display: block;

	position: relative;
	width: 100%;
	padding-top: 100%;

	transition: opacity 600ms;
}

.feed__anchor:hover {
	opacity: 0.5;
}

.feed__anchor img {
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: cover;
}