
/*
** newsletter
*/

.newsletter {

}

.newsletter__wrapper {
	text-align: center;
}

.newsletter__anchor {
	font-family: 'HLTSUC';
	font-size: 1em;
	line-height: 1em;
	text-decoration: underline;
}


.newsletter__anchor:hover {
	text-decoration: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.newsletter__anchor {
		font-size: 2.5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.newsletter__anchor {
		font-size: 2em;
	}
}