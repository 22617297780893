
/*
** projects
** page / panel / wysiwyg-projects
*/

body.body--projects .header {
	position: fixed;
}

.section--projects {
	min-height: 100vh;
}

.section--projects .page__wrapper {

}

.section--projects .page__name {
	display: none;
}

.section--projects .page__text {
	margin-top: 0em;
}

.section--projects .page__panel {

}

.section--projects .page__enter {
	display: none;
}

.section--projects .page__works {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	body.body--projects .footer {
		display: none;
	}

	body.body--projects:not(.body--scrolled) {
		color: var(--html-main-color);
	}

	body.body--scrolled .section--projects .page__panel {
		display: none;
	}

	body.body--projects:not(.body--scrolled) {
		background-color: var(--html-main-bgcolor);
	}

	/* page */
	.section--projects .page__works {
		margin-top: 5vh;
		transition: all 300ms;
	}

	body:not(.body--scrolled) .section--projects .page__works {
		filter: grayscale(1);
		mix-blend-mode: screen;
		opacity: var(--html-main-opacity);
	}

	/* works */
	.section--projects .works__item {
		transition: opacity 600ms;
	}

	body.body--idle.body--hover .section--projects .works__item:not(.hover) {
		opacity: 0;
		pointer-events: none;
	}

	/* works grid : category films */
	.section--projects[data-category-id='6'] .works.works--grid .works__item {
		margin: 2.5vw;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.section--projects .page__wrapper.wrapper {
		padding-top: 7em;
	}

	.section--projects .page__overlay {
		display: none;
	}

	.section--projects .page__enter {
		display: none;
	}

	.section--projects .page__works {
		margin-top: 2em;
	}
}