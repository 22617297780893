
/*
** actualities
*/

body.body--actualities .header {
	position: fixed;
}

.section--actualities {

}

.section--actualities .page__name {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	body.body--actualities {
		color: var(--html-main-color);
		background-color: var(--html-main-bgcolor);
	}

	/* page */
	.section--actualities .page__wrapper.wrapper {
		padding: 0;
	}

	.section--actualities .news__wrapper {
		filter: grayscale(1);
		mix-blend-mode: screen;
		opacity: var(--html-main-opacity);
		transition: all 300ms;
	}

	.section--actualities .news__wrapper:hover {
		filter: grayscale(0);
		mix-blend-mode: normal;
		opacity: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.section--actualities .page__wrapper.wrapper {
		padding-top: 4em;
	}
}