
/*
** rollover
** z-index: 200;
*/

.rollover {
	opacity: 0;
	pointer-events: none;

}

.rollover.hover {
	transition: opacity 300ms;
	opacity: 1;
}

.rollover__left {

}

.rollover__right {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.rollover__left,
	.rollover__right {
		position: fixed;
		bottom: 0em;
		z-index: 200;

		padding: 2rem;
		max-width: 50vw;

		font-family: 'HLTSUC';
		font-size: 3em;
		line-height: 0.9em;
	}

	.rollover__left {
		left: 0;
	}

	.rollover__right {
		right: 0;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.rollover {
		display: none;
	}
}