
/*
** menu
** sections / socials / langs
*/

.menu {

}

.menu__sections,
.menu__langs,
.menu__socials {
	display: inline-block;
	vertical-align: middle;
}

.menu__sections,
.menu__langs {
	margin-right: 1.5em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}