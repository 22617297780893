/*
** swiper (overload : node_modules/swiper/css/swiper.css)
*/

/* color */
body .player .swiper .swiper-caption,
body .player .swiper .swiper-pagination {
	color: #000;
}

body .player .swiper .swiper-slide,
body .player .swiper .swiper-slide:after {
	background-color: #fff;
}

/* swiper */
body .player .swiper {
	position: relative;
	width: 100%;
	height: 100vh;
	height: calc(var(--vh, 1vh) * 100);
}

body .player .swiper .swiper-wrapper {
	user-select: none;
}

/* loading */
body .player .swiper .swiper-slide:before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	content: '';
	background-color: #fff;
}

body .player .swiper .swiper-slide:after {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 10px;
	height: 10px;
	z-index: 3;

	content: '';
	border-radius: 100%;
	background-color: #000;

	transform: translate(-50%,-50%);
	animation: loader-animation 1.0s infinite ease-in-out;
}

/* image */
body .player .swiper .swiper-image {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 1;

	max-width: 1440px;
	max-height: 1440px;

	width : calc(100% - 140px);
	height: calc(100% - 0px);

	transform: translate(-50%,-50%);
}

body .player .swiper .swiper-image img {
	display: block;

	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 1;

	object-fit: contain;
	object-position: 50% 50%;
}

body .player .swiper .swiper-slide.swiper-slide--img.loaded:before,
body .player .swiper .swiper-slide.swiper-slide--img.loaded:after {
	opacity: 0;
}

/* video */
body .player .swiper .swiper-video {
	display: block;
	cursor: pointer;

	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 100%;
	z-index: 2;

	transform: translate(-50%,-50%);
}

body .player .swiper .swiper-slide--video img {
	opacity: 0;
}

body .player .swiper .swiper-slide.swiper-slide--video.loadeddata:before,
body .player .swiper .swiper-slide.swiper-slide--video.loadeddata:after {
	display: none;
}

/* overlay */
body .player .swiper .swiper-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;

	background-color: transparent;
}

body .player .swiper .swiper-slide--video .swiper-overlay {
	display: none;
}

/* pagination */
body .player .swiper-pagination {
	position: absolute;
	top: 1.5rem;
	left: 2rem;
	width: auto;
	z-index: 1;

	font-size: 0.8em;
	user-select: none;
}

/* caption */
body .player .swiper .swiper-caption {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;

	padding: 1.5rem 4rem;

	font-size: 0.8em;
	line-height: 1.5em;
	text-align: center;

	color: #000;
	background-color: rgba(255,255,255,0.9);

	opacity: 0;
	transition: opacity 600ms;
}

body .player .swiper .swiper-slide:hover .swiper-caption {
	opacity: 1;
}

/* prev next */
body .player .swiper .swiper-button-prev,
body .player .swiper .swiper-button-next {
	width: 4em;
	height: 4em;
	margin-top: -2em;

	background-color: transparent;
	background-position: center;
	background-repeat: no-repeat;
	background-size: 30%;

	transition: transform 300ms ease;
}

body .player .swiper .swiper-button-prev {
	left: 0em;
	background-image: url(/assets/img/swiper/previous.svg);
}

body .player .swiper .swiper-button-next {
	right: 0em;
	background-image: url(/assets/img/swiper/next.svg);
}

body .player .swiper .swiper-button-prev:hover {
	transform: translateX(-15%);
}

body .player .swiper .swiper-button-next:hover {
	transform: translateX(15%);
}

body .player .swiper .swiper-button-prev:after,
body .player .swiper .swiper-button-next:after {
	display: none;
}

body .player .swiper .swiper-button-prev:focus,
body .player .swiper .swiper-button-next:focus {
	outline: none;
}

/*
** responsive desktop
*/

@media only screen and ( max-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	body .player .swiper .swiper-image {
		width : 100%;
		height: calc(100% - 200px);
	}
}
