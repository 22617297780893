
/*
** sections
*/

.sections {
	margin: 0;
	padding: 0;
	list-style: none;
}

.sections__item {
	display: inline-block;
	vertical-align: middle;
}

.sections__item:not(:last-child) {
	margin-right: 1.5em;
}

.sections__anchor {
	font-size: 0.9em;
	font-weight: 500;
}

.sections__anchor:hover:after,
.sections__anchor.selected:after {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}