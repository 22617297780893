
/*
** header
** logo / categories / menu / burger / scroll
** z-index : 500
*/

.header {

}

.header__wrapper {

}

.header__logo {

}

.header__navigation {

}

.header__categories {

}

.header__menu {

}

.header__burger {

}

.header__scroll {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 500;
	}

	.header__logo,
	.header__categories {
		position: absolute;
		top: 2em;
		left: 2em;
		z-index: 1;
	}

	.header__categories {
		top: 7em;
	}

	.header__menu {
		position: absolute;
		top: 2em;
		right: 2em;
		z-index: 1;
	}

	.header__burger {
		display: none;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.header {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 500;
	}

	.header__navigation {
		position: fixed;
		top: 0em;
		right: 0em;
		width: 100%;
		height: 100%;
		z-index: 500;

		padding: 2em;
		overflow: hidden;

		color: #000;
		background-color: #fff;

		transform: translateY(-100%);
		transition: transform 600ms;
	}

	body.body--header-opened .header__navigation {
		color: #000;
		transform: translateY(0%);
	}

	.header__menu {
		margin-top: 2em;

		font-size: 1.2em;
	}

	.header__burger {
		position: absolute;
		top: 0.5em;
		right: 0.5em;
		z-index: 501;
	}

	body.body--header-opened .header__burger {
		color: #000;
	}

	.header__scroll {
		display: none;
	}
}