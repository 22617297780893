
/*
** underline
*/

.underline {
	display: inline-block;
	position: relative;
}

.underline:before,
.underline:after {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	height: 0.05em;

	background: currentColor;
	pointer-events: none;
}

.underline:before {
	content: '';
	transform-origin: 100% 50%;
	transform: scale3d(0, 1, 1);
	transition: transform 0.3s;
}

.underline.selected:before,
.underline:hover:before {
	transform-origin: 0% 50%;
	transform: scale3d(1, 1, 1);
}