
/*
** wysiwyg - videos
*/

.wysiwyg.wysiwyg--videos h3 {
	display: none;
}

.wysiwyg.wysiwyg--videos p {
	max-width: 30rem;
	margin-bottom: 0.5em;

	font-size: 0.8em;
	line-height: 1.4em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}