
/*
** instagram (z-index: 500)
*/

body.body--instagram {

}

.instagram {

}

/* container */
.instagram__container {
	position: fixed;
	top: 0;
	right: 0;
	width: 60vw;
	height: 100vh;
	z-index: 501;

	transition: transform 600ms ease-in-out;
	transform: translateX(100%);
}

body.body--instagram .instagram__container {
	transform: translateX(0);
}

.instagram__content {
	position: relative;
	width: 60vw;
	height: 100vh;

	overflow: hidden;
	overflow-y: auto;
	background-color: #fff;
}

.instagram__close {
	position: absolute;
	top: -0.4em;
	right: -0.4em;
	z-index: 1;

	color: #000;
	display: none;
}

/* overlay */
.instagram__overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 500;

	display: none;
	background-color: transparent;
	cursor: pointer;
}

body.body--instagram .instagram__overlay {
	display: block;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and (max-width: 640px ) {

	.instagram {
		display: none;
	}
}
