
/*
** langs
*/

.langs {
	margin: 0;
	padding: 0;
	list-style: none;
}

.langs__item {
	display: inline-block;
	vertical-align: middle;
}

.langs__anchor {
	position: relative;
	display: inline-block;

	font-size: 0.9em;
	font-weight: 500;
	text-transform: capitalize;
}

.langs__anchor:after {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 0%;
	height: 1px;
	z-index: 1;

	content: '';
	background-color: currentColor;
	transition: all 300ms;
}

.langs__anchor:hover:after {
	left: 10%;
	width: 80%;
}

.langs__anchor.selected {
	display: none;
}