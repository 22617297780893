
/*
** pagination
*/

.pagination {
	text-align: center;
}

.pagination__wrapper {
	display: inline-block;
	vertical-align: top;
}

.pagination__item {
	display: inline-block;
	vertical-align: top;

	padding: 5em 1em;

	font-size: 1em;
}

.pagination__item--previous,
.pagination__item--next {
	display: none;
}

.pagination__item--page {

}

.pagination__anchor {
	opacity: 0.5;
}

.pagination__anchor.selected,
.pagination__anchor:hover {
	opacity: 1;
}