/*
** HLTSUC
*/

@font-face {
	font-family: 'HLTSUC';
	src: url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.eot');
		src: url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.woff2') format('woff2'),
		url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.woff') format('woff'),
		url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.ttf') format('truetype'),
	url('/assets/font/HelveticaLTStd/HelveticaLTStd-UltraComp.svg#HelveticaLTStd-UltraComp') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

/*
** HN
*/

@font-face {
	font-family: 'HN';
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.eot');
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.woff2') format('woff2'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.woff') format('woff'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.ttf') format('truetype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Roman.svg#HelveticaNeue-Roman') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HN';
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.eot');
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.woff2') format('woff2'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.woff') format('woff'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.ttf') format('truetype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Bold.svg#HelveticaNeue-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'HN';
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.eot');
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.woff2') format('woff2'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.woff') format('woff'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.ttf') format('truetype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Medium.svg#HelveticaNeue-Medium') format('svg');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/*
@font-face {
	font-family: 'HN';
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Light.eot');
	src: url('/assets/font/HelveticaNeue/HelveticaNeue-Light.eot?#iefix') format('embedded-opentype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Light.woff2') format('woff2'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Light.woff') format('woff'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Light.ttf') format('truetype'),
		url('/assets/font/HelveticaNeue/HelveticaNeue-Light.svg#HelveticaNeue-Light') format('svg');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
*/

