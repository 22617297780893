
/*
** preloader (z-index: 1500)
*/

.preloader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1500;

	color: #000;
	background-color: #fff;
	transition: transform 600ms ease;
}

body .preloader.preloader--hide {
	transform: translateY(-100%);
}

/* content */
.preloader__content {
	position: absolute;
	top: 50%;
	left: 50%;
	z-index: 1;

	transform: translate(-50%,-50%);
	pointer-events: none;
}

/* hidden */
body .preloader.preloader--hidden {
	display: none;
}

/* no js */
html.no-js .preloader {
	display: none;
}

/* logo */
.preloader__content .logo .logo__title,
.preloader__content .logo .logo__subtitle {
	display: inline-block;
	vertical-align: middle;
}

.preloader__content .logo span {
	display: block;
	padding: 0.5em;
	overflow: hidden;

	opacity: 0;
	transform: translateY(150%);
	transition: all 1s ease;
}

.preloader__content .logo .logo__subtitle span {
	transition-delay: 300ms;
}

body .preloader.preloader--show .preloader__content .logo span {
	opacity: 1;
	transform: translateY(0);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.preloader__content .logo__anchor {
		font-size: 5vw;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.preloader__content .logo__anchor {
		font-size: 10vw;
	}
}