
/*
** wysiwyg - category
*/

.wysiwyg.wysiwyg--category div {
	transition: all 600ms;
}


.wysiwyg.wysiwyg--category h3 {
	padding-top: 0;
	margin-bottom: 0.4rem;

	font-family: 'HLTSUC';
	font-size: 2.2em;
	line-height: 1.2em;
	font-weight: normal;
	white-space: nowrap;

	transition: font-size 600ms;
}

.wysiwyg.wysiwyg--category p {
	overflow: hidden;
	max-height: 0;
	opacity: 0;

	font-size: 0.95em;
	line-height: 1.3em;

	transition: all 1s;
}

html.touchevents    .wysiwyg.wysiwyg--category div.hover p,
html.no-touchevents .wysiwyg.wysiwyg--category div:hover p {
	max-height: 7em;
	opacity: 1;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.wysiwyg.wysiwyg--category div:hover {
		padding-top: 0em;
		padding-bottom: 1.3em;
	}

	.wysiwyg.wysiwyg--category div:hover h3,
	.wysiwyg.wysiwyg--category div.hover h3 {
		font-size: 2.5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.wysiwyg.wysiwyg--category div:not(:last-child) {
		margin-bottom: 1em;
	}

	.wysiwyg.wysiwyg--category div p {
		max-width: 20em;
		margin: auto;
	}
}