
/*
** wysiwyg
*/

.wysiwyg h1,
.wysiwyg h2,
.wysiwyg h4,
.wysiwyg h5,
.wysiwyg h6,
.wysiwyg hr,
.wysiwyg blockquote,
.wysiwyg ul,
.wysiwyg li {
	display: none;
}

.wysiwyg h3,
.wysiwyg p {
	margin: 0;
	padding: 0;

	margin-bottom: 1.5em;
}

/* h3 */
.wysiwyg h3 {
	font-size: 1.4em;
	line-height: 1.4em;
	font-weight: bold;
}

/* p */
.wysiwyg p {
	font-size: 1em;
	line-height: 1.6em;
}

.wysiwyg p:last-child {
	margin-bottom: 0em;
}

/* a */
.wysiwyg a {
	text-decoration: underline;
}

.wysiwyg a:hover {
	text-decoration: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}