
/*
** works - grid
*/

.works.works--grid {
	text-align: center;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.works.works--grid {
		width: 100%;
		overflow: hidden;
	}

	.works.works--grid .works__container {
		position: relative;
		left: -4vw;
		width: calc(100% + 8vw);
	}

	.works.works--grid .works__item {
		display: inline-block;
		text-align: left;
		margin: 4vw;
		overflow: hidden;
	}

	.works.works--grid .works__info {
		display: none;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.works.works--grid .works__item {
		margin-bottom: 2em;
	}
}