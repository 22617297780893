
/*
** page
** poster
*/

.page {
	position: relative;
}

.page__poster {

}

.page__overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	opacity: 0;
	background-color: transparent;
	cursor: pointer;
}

body.body--scrolled .page__overlay {
	display: none;
}

.page__content {

}

.page__wrapper {

}

.page__info {
	max-width: 50em;
	margin: auto;
}

.page__back {
	margin-bottom: 0.5em;
}

.page__name {
	font-family: 'HLTSUC';
	font-size: 1em;
	line-height: 1em;
}

.page__image {
	margin-top: 2em;
}

.page__pdfs {
	margin-top: 2em;
}


.page__reader {
	padding-top: 1em;
}

.page__header {

}

.page__text {
	margin-top: 2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page__name {
		font-size: 3.5em;
	}

	.page__quote,
	.page__videos,
	.page__images {
		margin-top: 7em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page__name {
		font-size: 3em;
	}

	.page__quote,
	.page__videos,
	.page__images {
		margin-top: 4em;
	}
}