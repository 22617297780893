
/*
** burger
*/

.burger {
	position: relative;
	cursor: pointer;
	width: 3em;
	height: 3em;
}

.burger__shape {
	display: block;

	position: absolute;
	top: 50%;
	left: 50%;
	width: 1.5em;
	height: 2px;

	background-color: currentColor;
	transform: translate(-50%, -50%);
	transition: background 0.3s;
}

.burger__shape::before,
.burger__shape::after {
	content: '';
	display: block;

	position: absolute;
	top: 0;
	left: 0;
	width: 1.5em;
	height: 2px;

	background-color: currentColor;
	transform: translateY(-0.5em);
	transition: transform 0.3s, background 0.3s;
}

.burger__shape::after {
	transform: translateY(0.5em);
}

/* open */
body.body--header-opened .burger__shape {
	background: transparent;
}

body.body--header-opened .burger__shape::before {
	transform: translateY(0) rotate(45deg);
}

body.body--header-opened .burger__shape::after {
	transform: translateY(0) rotate(-45deg);
}
