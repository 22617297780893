
/*
** top
*/

.top {
	position: fixed;
	top: 50%;
	right: 1em;
	z-index: 1;

	transform: translateY(-50%);
}

.top.top--hidden {
	opacity: 0;
	pointer-events: none;
}

/* button */
.top__button.button {
	transition: transform 300ms;
}

.top__button.button:hover {
	transform: translateY(-20%);
}

.top__button .button__svg {
	width: 1.5em;
	height: 2em;
}

.top__button .button__label {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.top {
		display: none;
	}
}