
/*
** page - poster
*/

.page.page--poster {
	position: relative;
}

/* poster */
.page.page--poster .page__poster {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	z-index: -1;

	transform-origin: top center;
	transition: all 300ms;
}

body.body--scrolled .page.page--poster .page__poster {
	opacity: 0;
	transform: scale(0.9);
}

/* content */
body:not(.body--scrolled) .page.page--poster .page__content {
	color: #fff;
}

body:not(.body--scrolled) .page.page--poster .page__back {
	display: inline-block;
	position:relative;
	z-index: 2;
}

body:not(.body--scrolled) .page.page--poster .page__tags,
body:not(.body--scrolled) .page.page--poster .page__reader,
body:not(.body--scrolled) .page.page--poster .page__images {
	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.page.page--poster .page__wrapper.wrapper {
		padding-top: 3em;
	}

	.page.page--poster .page__content {
		padding-top: 70vh;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.page.page--poster .page__wrapper.wrapper {
		padding-top: 2em;
	}

	.page.page--poster .page__content {
		padding-top: 60vh;
	}
}