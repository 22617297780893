
/*
** works - fullrow
*/

.works.fullrow {

}

.works.works--fullrow .works__container:after {
	content: '';
	clear: both;
	display: table;
}

.works.works--fullrow .works__item {
	float: left;
}

.works.works--fullrow .works__wrapper {
	position: relative;
}

.works.works--fullrow .works__info {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}