
/*
** wysiwyg - work
*/

.wysiwyg.wysiwyg--work {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}