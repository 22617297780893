
/*
** news
*/

.news {

}

.news__container {

}

.news__item {

}

.news__wrapper {
	position: relative;
}

.news__info {
	padding: 1em;
}

.news__name {
	font-size: 1.2em;
	line-height: 1.3em;
}

.news__header {

}

.news__anchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 1;

	opacity: 0;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.news {
		width: 100%;
		overflow: hidden;
	}

	.news__container {
		position: relative;
		left: -4vw;
		width: calc(100% + 8vw);

		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	.news__sizer {
		display: none;
	}

	.news__sizer,
	.news__item {
		width: 50%;
	}

	.news__item {
		padding-left: 4vw;
		padding-right: 4vw;
		margin-bottom: 15vw;
	}

	.news__item:nth-child(2) {
		padding-top: 30em;
	}

	.news__wrapper {
		display: flex;
		justify-content: center;
	}

	.news__item .news__content { width: 100%; }
	.news__item.portrait .news__content { width: 75%; }

	.news__header {
		opacity: 0;
		transition: opacity 600ms;
	}

	.news__item:hover .news__header {
		opacity: 1
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.news__item {
		margin-bottom: 2em;
	}
}