
/*
** pdfs
*/

.pdfs {

}

.pdfs__label {
	text-decoration: underline;
}

.pdfs__list {
	margin-top: 0.5em;
}

.pdfs__item {

}

.pdfs__anchor {

}

.pdfs__anchor:hover {
	opacity: 0.5;
}

/* button */
.pdfs .button__svg {
	width: 1.2em;
	height: 1.2em;
	transform: translateY(-0.2em);
}

.pdfs .button__label {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}