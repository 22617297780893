
/*
** studio
** page / poster / wysiwyg-page
*/

body.body--studio:not(.body--scrolled) {
	color: #fff;
	background-color: #111;
}

.section--studio {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}