
/*
** footer
** top
*/

body.body--transition .footer {
	opacity: 0;
	pointer-events: none;
}

.footer {

}

.footer__newsletter {

}

.footer__wrapper {

}

.footer__copyright,
.footer__sections {
	font-size: 0.9em;
}

.footer__top {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.footer {
		padding-top: 10em;
	}

	.footer__wrapper {
		display: flex;
	}

	.footer__copyright,
	.footer__sections {
		width: 50%;
	}

	.footer__sections {
		text-align: right;
	}

	.footer__newsletter {
		margin-bottom: 10em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.footer {
		padding-top: 5em;
	}

	.footer__wrapper {
		text-align: center;
	}

	.footer__newsletter {
		margin-bottom: 5em;
	}
}