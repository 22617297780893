
/*
** newsletter
*/

body.body--newsletter:not(.body--scrolled) {
	color: var(--html-main-color);
	background-color: var(--html-main-bgcolor);
}

body.body--newsletter .footer__newsletter {
	display: none;
}

/* embed */
body #mc_embed_signup {
	margin: 0;
	padding: 0;
	width: 100%;
	max-width: 25em;

	font-size: 1em;
}

body  #mc_embed_signup form {
	margin: 0;
	padding: 0;
}

body #mc_embed_signup div#mce-responses {
	float: none;
	top: 0;
	margin: 0;
	padding: 0;
	overflow: hidden;
	width: 100%;
	clear: both;
}

body #mc_embed_signup div.response {
	padding: 0;
	margin: 0;
	margin-bottom: 2em;

	float: none;
	top: 0;
	z-index: 1;

	width: 100%;
	max-width: 20em;

	font-weight: normal;
}

body #mc_embed_signup #mce-success-response {
	color: var(--html-main-color);
}



body #mc_embed_signup .button,
body #mc_embed_signup .button:hover {
	color: var(--html-main-bgcolor);
	background-color: var(--html-main-color);
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}