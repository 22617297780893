
/*
** videos
*/

.videos {

}

.videos__container {
	max-width: 70em;
	margin: auto;

}

.videos__item {

}

.videos__embed {

}

.videos__text {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.videos__item:not(:last-child) {
		margin-bottom: 5em;
	}

	.videos__text {
		padding: 2em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.videos__item:not(:last-child)  {
		margin-bottom: 3em;
	}

	.videos__text {
		margin-top: 1em;
	}
}