
/*
** quote
*/

.quote {
	position: relative;
	color: var(--html-main-color);
}

.quote:after {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;

	content: '';
	background-color: var(--html-main-bgcolor);
	opacity: var(--html-main-quote-bg-opacity);
}

.quote .wysiwyg.wysiwyg--quote p {
	line-height: 1.2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.quote__wrapper {
		padding: 10vw 5vw;
	}

	.quote .wysiwyg.wysiwyg--quote p {
		font-size: 4vw;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.quote__wrapper {
		padding: 2em;
	}

	.quote .wysiwyg.wysiwyg--quote p {
		font-size: 1.5em;
	}
}