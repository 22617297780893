
/*
** logo
*/

.logo {
	border: 1px solid transparent;
}

.logo__anchor {
	font-family: 'HLTSUC';
	font-size: 1em;
	line-height: 0.8em;
	white-space: nowrap;
}

.logo__title {

}

.logo__subtitle {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.logo__anchor {
		font-size: 3.5em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.logo__anchor {
		font-size: 2em;
	}
}