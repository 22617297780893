
/*
** socials
*/

.socials {
	display: flex;
}

.socials__item {
	position: relative;
}

.socials__anchor {
	color: inherit;
}

.socials__anchor:hover {

}

/* button */
.socials .button {
	transition: none;
}

.socials .button__svg {
	width: 1.6em;
	height: 1.6em;
}

.socials .button__label {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.socials__overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1;

		cursor: pointer;
		background-color: transparent;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.socials__overlay {
		display: none;
	}
}