
/*
** wysiwyg - images
*/

.wysiwyg.wysiwyg--images h3 {
	display: none;
}

.wysiwyg.wysiwyg--images p {
	margin-bottom: 0;

	font-size: 0.8em;
	line-height: 1.4em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}