
/*
** home
** page / poster / works-fullrow
*/

body.body--home {

}

body.body--home .header {
	position: fixed;
}

body.body--home .footer {

}

/* section */
.section--home {

}

.section--home .page__name {
	display: none;
}

.section--home .wrapper {
	padding: 0em;
}

.section--home .page__poster {
	margin-bottom: 1px;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	body.body--home {
		color: var(--html-main-color);
		background-color: var(--html-main-bgcolor);
	}

	.section--home .poster__wrapper,
	.section--home .works__wrapper {
		filter: grayscale(1);
		mix-blend-mode: screen;
		opacity: var(--html-main-opacity);

		transition: all 300ms;
	}

	.section--home .poster__wrapper:hover,
	.section--home .works__wrapper:hover {
		filter: grayscale(0);
		mix-blend-mode: normal;
		opacity: 1;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	body.body--home .header {
		color: #fff;
	}

	/* quote */
	.section--home .page__quote {
		margin-top: 0em;
	}
}