
/*
** block
*/

.block {
	position: relative;
}

.block__wrapper {
	max-width: 50em;
	margin: auto;
}

.block__label {
	font-family: 'HLTSUC';
	font-size: 1em;
	line-height: 0.9em;
}

.block__content {
	margin-top: 2em;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.block__label {
		font-size: 3em;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.block__label {
		font-size: 2.5em;
	}
}