
/*
** project
** page / poster / works-grid / wysiwyg-project
*/

body.body--project:not(.body--scrolled) {
	color: #fff;
}

.section--project {

}

.section--project .page__name {
	text-decoration: underline;
}

.section--project .page__tags {
	margin-top: 1em;
	font-size: 1em;
	font-weight: bold;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	/* page */
	.section--project .page__wrapper.wrapper {
		padding-left: 0;
		padding-right: 0;
	}

	.section--project .page__info {
		max-width: 54em;
		padding-left: 2em;
		padding-right: 2em;
	}

	.section--project .works.works--grid .works__info {
		display: block;
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}