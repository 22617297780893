
/*
** reader
*/

.reader {

}

.reader__text {

}

/* button */
.reader__button {
	cursor: pointer;
	display: inline-block;
	margin-top: 2em;

	font-size: 0.9em;
	opacity: 1;
	transition: opacity 600ms;
}

.reader__button:hover{
	opacity: 0.5;
}

/* opened */
.reader:not(.opened) .reader__complement {
	display: none;
}

.reader.opened .reader__button--more,
.reader:not(.opened) .reader__button--less {
	display: none;
}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

}