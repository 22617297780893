
/*
** panel
*/

.panel {

}

/*
** responsive desktop
*/

@media only screen and ( min-width: 641px ) {

	.panel {
		position: absolute;
		top: 0;
		right: 0;
		height: 100vh;
		z-index: 100;

		display: flex;
	}

	.panel__content {
		max-width: 27em;
		padding: 4em;
		align-self: center;

		color: var(--html-main-color);
		background-color: var(--html-main-bgcolor);
	}
}

/*
** responsive mobile
*/

@media only screen and ( max-width: 640px ) {

	.panel__content {
		padding: 1em;
		text-align: center;
	}
}